import { configurationService } from "@/business/configurationService";
import i18n from "@/i18n";
import { unitParser, TimeRanges } from '@colven/common-domain-lib/lib';
import * as deepcopy from "deepcopy";
import { caliperApi } from "../api/caliper.api";
import { CALIBRATING_TIME_COLORS, CALIPER_CHART_DATASET_TEMPLATE, CALIPER_CHART_SUMMARY_LABELS, CALIPER_CHART_SUMMARY_TEMPLATE, CALIPER_MAP_REFERENCE_TEMPLATE, CALIPER_SUMMARY_MAP_JSON_TEMPLATE, CALIPER_SUMMARY_MAP_POINT_JSON_TEMPALTE, CHART_TYPE_COLORS, TRENDS_STATES_ICONS } from "../constants/caliperConstants";
import { processFilters } from "../tools/functions";
import store from "@/store/store";

export const caliperSummaryService = (function () {
    'use strict';
  
    async function getSummaryReport(filters) {
        const filterData = processFilters(filters);
        filterData.reportType = "CALIPER_SUMMARY";
        filterData.originalFilters = filters;
        let reportData = null;
        if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
            await caliperApi.getSummaryReportByDate(filterData);
            return "ok";
        } else {
            const data = await caliperApi.getSummaryReportFixedRange(filterData);
            reportData = {
                data: data,
                filters: filterData,
            }
            return await generateReportByData(reportData);
        }
    }

    async function generateReportByData(reportData) {
        let tableData = null;
        let maps = null;
        const hardwareAbleMap = getHardwareAbleMap(reportData.data);
        if (reportData.filter) {
            const finalData = reportData.data.filter((thingStatus) => { return thingStatus.totalCalibratingTime > reportData.filter.from && thingStatus.totalCalibratingTime <= reportData.filter.to });
            tableData = getSummaryTableData(finalData, hardwareAbleMap);
            maps = await getSummaryMap(finalData);
        } else {
            tableData = getSummaryTableData(reportData.data, hardwareAbleMap);
            maps = await getSummaryMap(reportData.data);
        }
        const charts = getSummaryChart(reportData.data);
        return {
            charts: charts || [],
            maps: maps.maps,
            tableData: tableData,
            initPosition: maps.initPosition ? maps.initPosition : null,
            reportData: reportData,
            hardwareAbleMap: hardwareAbleMap
        }
    }

    async function getSummaryReportByDate(reportData) {
        const hardwareAbleMap = getHardwareAbleMap(reportData.data);
        const charts = getSummaryChart(reportData.data);
        const tableData = getSummaryTableData(reportData.data, hardwareAbleMap);
        const maps = await getSummaryMap(reportData.data);
        return {
            charts: charts || [],
            maps: maps.maps,
            tableData: tableData,
            initPosition: maps.initPosition,
            reportData: reportData,
            hardwareAbleMap: hardwareAbleMap
        }
    }

    function getHardwareAbleMap(data) {
        const result = {};
        if (data && data.length) {
            for (const thingData of data) {
                if (thingData.caliperConfig && thingData.caliperConfig.pressure && thingData.caliperConfig.pressure.hardwareNumber) {
                    result[thingData.idThing] = Object.keys(thingData.caliperConfig.pressure.hardwareNumber);
                }
            }
        }
        return result;
    }

    async function getSummaryMap(data) {
        const map = deepcopy(CALIPER_SUMMARY_MAP_JSON_TEMPLATE);
        const mapConfig = await configurationService.get("caliperSummary.map");
        const mapCurrentLayer = "Google Road";
        let initPosition = {
            lat: null,
            long: null,
            zoom: 4,
            currentLayer: mapCurrentLayer
        }
        if (mapConfig?.data?.currentLayer) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }
        if (data && data.length > 0) {
            for (const mapData of data) {
                if (mapData.totalTracks > 0) {
                    const reference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
                    const caliperPoint = deepcopy(CALIPER_SUMMARY_MAP_POINT_JSON_TEMPALTE);
                    reference.name = mapData.thingName;
                    reference.title = mapData.thingName;
                    map.groups[0].references.push(reference);
                    caliperPoint.id = 10;
                    caliperPoint.lat = mapData.position.lat;
                    caliperPoint.lng = mapData.position.lng;
                    caliperPoint.value = mapData.thingName;
                    caliperPoint.marker.title = `${i18n.t('caliperSummary.map.vehicle')}: ${mapData.thingName}`;
                    map.groups[0].series[0].points.push(caliperPoint);
                    initPosition.lat = mapData.position.lat;
                    initPosition.long = mapData.position.lng;
                }
            }
        }
        /* if (!initPosition.lat) {
            initPosition = null;
        } */
        return {
            maps: [map],
            initPosition
        };
    }

    function getSummaryTableData(data, hardwaresAble) {
        const result = [];
        if (data && data.length > 0) {
            for (const thingData of data) {
                if (thingData.totalTracks > 0) {
                    const hardwareAble = hardwaresAble[thingData.idThing] ? hardwaresAble[thingData.idThing] : [];
                    result.push({
                        thingName: thingData.thingName,
                        calibrationTimeColor: getCalibratingTimeColor(thingData),
                        calibratingTimeString: calibratingTimeString(thingData.totalCalibratingTime),
                        trend: getTrends(thingData, hardwareAble),
                        state: thingData.state,
                        changesCount: thingData.statesChangesCount ? thingData.statesChangesCount : (thingData.changesCount ? thingData.changesCount : 0),
                        calibratingStopTime: thingData.calibratingStopTime || 0,
                        calibratingDistance: getCalibratingDistance(thingData, hardwareAble),
                        inactiveDistance: getInactiveDistance(thingData, hardwareAble),
                        distance: parseToKm((thingData.distance || 0).toFixed(2))
                    });
                }
            }
        }
        return result;
    }

    function getCalibratingTimeColor(thingData) {
        const calibrationTime = thingData.totalCalibratingTime || 0;
        if (calibrationTime > 0 && calibrationTime < 7200) {
            return CALIBRATING_TIME_COLORS.ZERO_TWO_HOURS;
        }
        if (calibrationTime >= 7200 && calibrationTime < 14400) {
            return CALIBRATING_TIME_COLORS.TWO_FOUR_HOURS;
        }
        if (calibrationTime >= 14400) {
            return CALIBRATING_TIME_COLORS.MORE_FOUR_HOURS;
        }
    }

    function getTrends(thingData, hardwareAble) {
        let result = '';
        if (thingData.detailsHardwareData.trend) {
            for (const hardwareNumber in thingData.detailsHardwareData.trend) {
                if (hardwareAble.includes(hardwareNumber)) {
                    result += `${i18n.t('caliperSummary.hardwareNames.' + hardwareNumber)}: ${getTrend(thingData.detailsHardwareData.trend[hardwareNumber])}\n`;
                }
            }
            return result;
        }
    }

    function getTrend(trend) {
        switch (trend) {
            case 'UP':
                return TRENDS_STATES_ICONS.UP;
            case 'DOWN':
                return TRENDS_STATES_ICONS.DOWN;
            case 'NC':
                return TRENDS_STATES_ICONS.NC;
        }
    }

    function getCalibratingDistance(thingData, hardwareAble) {
        let result = '';
        for (const hardwareNumber in thingData.detailsHardwareData.calibratingDistance) {
            if (hardwareAble.includes(hardwareNumber)) {
                result += `${i18n.t('caliperSummary.hardwareNames.' + hardwareNumber)}: ${parseToKm((thingData.detailsHardwareData.calibratingDistance[hardwareNumber] || 0).toFixed(2))}\n`;
            }
        }
        return result;
    }

    function getInactiveDistance(thingData, hardwareAble) {
        let result = '';
        for (const hardwareNumber in thingData.detailsHardwareData.inactiveDistance) {
            if (hardwareAble.includes(hardwareNumber)) {
                result += `${i18n.t('caliperSummary.hardwareNames.' + hardwareNumber)}: ${parseToKm((thingData.detailsHardwareData.inactiveDistance[hardwareNumber] || 0).toFixed(2))}\n`;
            }
        }
        return result;
    }

    function calibratingTimeString(time) {
        const hours = parseInt(time / 3600);
        const minutes = parseInt((time % 3600) / 60);
        const seconds = parseInt(time % 60);
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds} `;
    }

    function parseToKm(distance) {
        let distances = distance || 0        
        return unitParser.parseDistance(distances, store.getters['user/getInternationalization'].unit , true);
    }

    function getSummaryChart(data) {
        // CHART init
        const chart = deepcopy(CALIPER_CHART_SUMMARY_TEMPLATE);
        chart.id = new Date().getTime();
        chart.name = i18n.t(chart.name);
        const times = getChartColumns(data);
        chart.data.labels = CALIPER_CHART_SUMMARY_LABELS.map((label) => i18n.t(label));
        chart.data.datasets = getInitDataset(times);
        return [chart];
    }

    function getChartColumns(data) {
        const result = {
            FIRST: 0,
            SECOND: 0,
            THIRD: 0
        }
        if (data && data.length > 0) {
            data.forEach(thingData => {
                const calibrationTime = thingData.totalCalibratingTime || 0;
                if (calibrationTime > 0 && calibrationTime < 7200) {
                    result.FIRST++;
                }
                if (calibrationTime >= 7200 && calibrationTime < 14400) {
                    result.SECOND++;
                }
                if (calibrationTime >= 14400) {
                    result.THIRD++;
                }
            });
        }
        return result;
    }

    function getInitDataset(times) {
        const result = [];
        const caliperDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        caliperDataset.label = i18n.t('caliperSummary.chart.dataset.label');
        for (const time in times) {
            caliperDataset.backgroundColor.push(CHART_TYPE_COLORS[time]);
            caliperDataset.data.push(times[time]);
        }
        result.push(caliperDataset);
        return result;
    }

    return {
        getSummaryReport,
        getSummaryReportByDate,
        generateReportByData
    }

})();
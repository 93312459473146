import reportStorageApi from '@/api/report-storage.api';
import { breadcrumbService } from "@/business/breadcrumb.service";
import { caliperSummaryService } from "@/business/caliperSummaryService";
import { configurationService } from "@/business/configurationService";
import { filterService } from "@/business/filterService";
import CaliperDetailsComponent from '@/components/caliper/caliperDetails/CaliperDetailsComponent.vue';
import BreadcrumbComponent from "@/components/commons/breadcrumb/BreadcrumbComponent.vue";
import NoDataAvailableComponent from '@/components/commons/no-data-available/NoDataAvailableComponent.vue';
import FilterComponent from '@/components/filter/FilterComponent.vue';
import { CALIPER_TABLE_HEADERS, SUMMARY_REPORT_CHART_FILTERS } from "@/constants/caliperConstants";
import { SnackbarStyle } from "@/constants/constants";
import i18n from "@/i18n";
import store from "@/store/store";
import { mapActions, mapMutations } from 'vuex';

export default {
  components: {
    BreadcrumbComponent,
    CaliperDetailsComponent,
    FilterComponent,
    NoDataAvailableComponent
  },
  name: 'CaliperSummaryComponent',
  data() {
    return {
      init: true,
      tabs: [{
        id: 'tab-summary',
        name: i18n.t('caliperSummary.breadcrumbTabSummary')
      }],
      chartButtons: [
      ],
      thingsSeleted: null,
      loading: false,
      showOnlyLast123Days: false,
      currentTab: 'tab-summary',
      breadcrumbButtons: [],
      showFilters: false,
      filters: filterService.getFilterModel(),
      getDetailsForChart: null,
      tableFilterRules: {},
      loadingTable: false,
      tableDataKey: "number",
      detailsForTable: '',
      tableConfig: {},
      highlightRow: null,
      initPosition: null,
      selectedReferences: [],
      thingDetails: {},
      detailsKey: 'first',
      reportData: null,
      maxQuantityLimit: 100,
      orderFilterWorstBestPropertyName: i18n.t("filters.tabOrder.worstBest"),
      rowActions: [
        {
          id: 1,
          name: i18n.t("detail"),
          color: "blue",
          icon: "mdi-file-document-outline",
        },
      ],
      dataForRangeFilter: {
        showRangeData: false,
        minLimit: 0,
        maxLimit: 1000,
        propertyName: "ppty",
      },
      rowButtonClicked(button, data) {
        switch (button.id) {
          case 1:
            this.goToDetail(data);
            break;
          default:
            console.log("default");
        }
      },
      model: {
        charts: [],
        table: {
          headers: CALIPER_TABLE_HEADERS,
          data: []
        },
        maps: []
      },
      renderKey: (Math.random() + 1).toString(36).substring(2),
      configFiltersName: "product.caliperSummary.filters",
      homeInterval: null
    }
  },
  computed: {
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar"
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    tabSelected(id) {
      this.currentTab = id
      if (this.currentTab === 'tab-summary' && this.tabs.length === 2) {
        this.resetBreadcrumbSelectors();
        this.tabs.pop();
      }
    },
    closeFilter() {
      this.showFilters = false;
    },
    selector() {
      this.showFilters = !this.showFilters;
    },
    async saveFilterChanges() {
      this.thingsSeleted = null;
      configurationService
        .save(this.configFiltersName, { filters: this.filters })
        .then(() => {
        })
        .catch(() => {
        });
      this.closeFilter();
      try {
        this.loading = true;
        const result = await caliperSummaryService.getSummaryReport(this.filters);
        if (result && result !== 'ok') {
          this.setReport(result);
          if (this.isHomeRoute()) {
            clearInterval(this.homeInterval);
            this.homeInterval = setInterval(async () => {
              const result = await caliperSummaryService.getSummaryReport(this.filters);
              this.setReport(result);
            }, 15000);//15 min 900000
          }
        } else {
          this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
        }
      } catch (err) {
        console.log("Error:", err)
      } finally {
        this.init = false;
        this.loading = false;
      }
    },
    async setReportByKey(key) {
      const report = await reportStorageApi.getReport(key);
      const result = await caliperSummaryService.generateReportByData(report);
      this.filters = report ? report.filters : this.filters;
      this.setReport(result)
      this.init = false;
    },
    setReport(report) {
      this.resetBreadcrumbSelectors();
      if (report) {
        this.initPosition = report.initPosition;
        this.model.charts.cleanAndUpdate(report.charts);
        this.model.table.data.cleanAndUpdate(report.tableData);
        this.model.maps.cleanAndUpdate(report.maps);
        this.reportData = report.reportData.data;
        breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CALIPER_SUMMARY_REPORT');
      } else {
        this.cleanData();
        this.showFilters = true;
      }
    },
    async resetChartFilter() {
      const result = await caliperSummaryService.generateReportByData({ data: this.reportData });
      this.setReport(result);
      this.chartButtons.clean();
    },
    async filterDataByThings(chartData) {
      const [from, to] = SUMMARY_REPORT_CHART_FILTERS[chartData._index];
      const result = await caliperSummaryService.generateReportByData({ data: this.reportData, filter: { from, to } });
      this.setReport(result);
      if (this.chartButtons.length === 0) {
        this.chartButtons.push({ id: 'resetFilter', name: 'Reestablecer Filtros', icon: 'mdi-eye-off', function: this.resetChartFilter.bind(this) });
      }
    },
    cleanData() {
      // this.initPosition = null;
      // this.model.charts.cleanAndUpdate([]);
      // this.model.maps.cleanAndUpdate([]);
      // this.model.table.data.cleanAndUpdate([]);
    },
    saveTableConfig(data) {
      configurationService.save("product.caliperSummary.table", data)
        .then(() => {
          this.showSnackbar({
            visible: true,
            text: i18n.t("user.configuration.saveSuccess"),
            timeout: 10000,
            style: SnackbarStyle.SUCCESS
          });
        })
        .catch(() => {
          this.showSnackbar({
            visible: true,
            text: i18n.t("user.configuration.saveError"),
            timeout: 10000,
            style: SnackbarStyle.ERROR
          });
        });
    },
    goToDetail(data) {
      this.$router.push({
        name: "caliperDetails",
        params: { filters: this.filters, reportData: this.reportData, thingName: data.thingName }
      });
    },
    clickOnRow(data) {
      const thingData = this.reportData.find(thingData => thingData.thingName === data.thingName);
      const mapRef = this.$refs.caliperSummaryReportMap;
      if (mapRef != null && thingData && thingData.position.lat != null && thingData.position.lng != null) {
        mapRef.centerMap([thingData.position.lat, thingData.position.lng]);
      }
    },
    refreshTable() {
      // console.log('refresh table');
    },
    onMapClick() {
      // console.log("on click map");
    },
    async filtersChanged(data) {
      await configurationService.save("caliperSummary.map", { currentLayer: data.currentLayer });
    },
    resetBreadcrumbSelectors() {
      store.dispatch("breadcrumb/cleanBreadcrumb");
    },
    isHomeRoute() {
      return this.$route.name === 'home';
    },
    async getInitFilters() {
      /**
       * Fix para reutlizar codigo del resumen en el home
       */
      if (this.isHomeRoute()) {
        this.showFilters = false;
        // this.rowActions = [];
        this.configFiltersName = "product.caliperSummaryHome.filters";
        this.showOnlyLast123Days = true;
        const configuration = await configurationService.get(this.configFiltersName);
        const defaultFilter = filterService.getFilterModel();
        defaultFilter.dateData.selectedDateAndTimeRange = 't24Hs';
        this.filters = configuration && configuration.data && configuration.data.filters
          ? configuration.data.filters
          : defaultFilter;
        this.renderKey = (Math.random() + 1).toString(36).substring(2);
        const result = await caliperSummaryService.getSummaryReport(this.filters);
        this.setReport(result);
        this.homeInterval = setInterval(async () => {
          const result = await caliperSummaryService.getSummaryReport(this.filters);
          this.setReport(result);
        }, 900000);//15 min 900000
      }
    }
  },
  created() {
    this.breadcrumbButtons.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: () => { return false },
        function: this.selector.bind(this)
      }
    );
  },
  async mounted() {
    this.loading = true;
    const tableConfiguration = await configurationService.get("product.caliperSummary.table");
    this.tableConfig = (tableConfiguration && tableConfiguration.data) || {};
    const reportKey = this.$route.query.key;
    const report = reportKey && await reportStorageApi.getReport(reportKey);
    if (report) {
      const result = await caliperSummaryService.generateReportByData(report);
      this.filters = report ? report.filters : this.filters;
      this.setReport(result);
    } else {
      if (this.$route.params && Object.keys(this.$route.params).length) {
        const filters = this.$route.params.filters;
        const reportData = this.$route.params.reportData;
        const result = await caliperSummaryService.generateReportByData({ data: reportData, filters: filters });
        this.filters = filters;
        this.setReport(result);
        result.filters = filters;
      } else {
        this.filters = filterService.getFilterModel();
        this.showFilters = true;
        this.resetBreadcrumbSelectors();
        this.getInitFilters();
      }
    }
    this.loading = false;
  },
  beforeDestroy() {
    clearInterval(this.homeInterval);
  }
}
